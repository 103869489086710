import { VStack, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";

interface CardProps {
    datetime: string;
    downloadUrl: string;
    isMobile: boolean;
}

const Card = ({ datetime, downloadUrl, isMobile }: CardProps) => {
    return (
        <VStack
            px={isMobile ? "25px" : "200px" }
            py="20px"
            bgColor="#EDF2F7"
            w="100%"
            flexDir="row"
            justifyContent="space-between"
        >
            <Heading
                fontFamily="Montserrat"
                fontSize="12pt"
                fontWeight="bold"
                textAlign="center"
                color="#fc195a"
            >
                Edição {datetime}
            </Heading>
            <Stack
                flexDir="column"
                alignItems="center"
                justifyContent="start"
            >
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                    w="100%"
                    gap="50px"
                >
                    <Link href={downloadUrl} isExternal>
                        <Stack
                            flexDir="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text
                            >
                                Baixar
                            </Text>
                            <Stack pb="12px" pl="7px"><FaDownload /></Stack>
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
        </VStack>

    );
}

export default Card;
