import { Heading, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { useCheckMobile } from "../../utils/CheckMobile";
import Card from "./components/card";
import MagazineImage from "./components/magazine-image";

const currentEdition = {
    downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
    datetime: "Janeiro/2023"
}

const MockRevistaData = [
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Dezembro/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Novembro/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Outubro/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Setembro/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Agosto/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Julho/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Junho/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Maio/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Abril/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Março/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Fevereiro/2022"
    },
    {
        downloadUrl: "http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
        datetime: "Janeiro/2022"
    },
]

const Revista = () => {
    const isMobile = useCheckMobile();

    return (
        <VStack
            alignItems="center"
            justifyContent="center"
            w="100%"
            spacing={0}
        >
            <NavBar />
            <Stack
                height="100vh"
                width="100%"
            >
                <Stack
                    h="100vh"
                    direction="column"
                    bgGradient="linear(to-t, #D82B60, #FFA800)"
                    height="500px"
                    paddingTop="150px !important"
                    width="100%"
                    id="top"
                >
                    <Heading
                        color="white"
                        fontFamily="Chivo, sans-serif"
                        fontWeight="bold"
                        fontSize={isMobile ? "30pt" : "40pt"}
                        textAlign="center"
                        mb="40px"
                    >
                        REVISTA PARTIU
                    </Heading>
                    <Stack
                        direction="row"
                        alignSelf="center"
                        textAlign={{ base: "center", lg: "left" }}
                        justifySelf="center"
                    >
                        <MagazineImage
                            isMobile={isMobile}
                            imageUrl="https://cdn.discordapp.com/attachments/677847530326982683/1085295377894363236/image.png"
                            zIndex="3"
                            left={isMobile ? "180px" : "200px"}
                        />
                        <MagazineImage
                            isMobile={isMobile}
                            imageUrl="https://cdn.discordapp.com/attachments/677847530326982683/1085295525261217993/image.png"
                            zIndex="2"
                            top={"30px"}
                            right={isMobile ? "70px" : "50px"}
                        />
                        <MagazineImage
                            isMobile={isMobile}
                            imageUrl="https://cdn.discordapp.com/attachments/677847530326982683/1085295756015042560/image.png"
                            zIndex="1"
                            top="60px"
                            right={isMobile ? "320px" : "300px"}
                        />
                    </Stack>
                </Stack>
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                    w="100%"
                    pt={isMobile ? "200px" : "450px"}
                >
                    
                    <Link
                        href={currentEdition.downloadUrl}
                        download="current-edition.pdf"
                        target="_blank"
                        rel="noreferrer noopener"
                        _hover={{ textDecoration: "none" }}
                    >
                        <Stack
                            flexDir="row"
                            alignItems="center"
                            justifyContent="center"
                            color="white"
                            bgColor="#fc195a"
                            h="60px"
                            p="10px"
                            whiteSpace="normal"
                            text-align="center"
                            fontWeight="semibold"
                            lineHeight="20px"
                            fontSize={{ base: "15px", lg: "17px" }}
                            borderRadius="lg"
                            _hover={{
                                boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
                                transform: "translateY(-5px)",
                            }}
                        >
                            <Text
                                fontSize="14pt"
                            >
                                Baixar última edição
                            </Text>
                            <Stack pb="12px" pl="7px"><FaDownload size={20} /></Stack>
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
            <Stack
                direction="column"
                w="100%"
            >
                <Heading
                    color="#fc195a"
                    fontFamily="Chivo, sans-serif"
                    fontWeight="bold"
                    fontSize={isMobile ? "15pt" : "30pt"}
                    textAlign="center"
                    mb="40px"
                    mt={isMobile ? "0px" : "200pt"}
                >
                    OUTRAS EDIÇÕES
                </Heading>
                <Stack
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    spacing="0"
                    flexWrap="wrap"
                    gap="10px"
                >
                    {
                        MockRevistaData.map(({datetime, downloadUrl}) => (
                            <Card
                                isMobile={isMobile}
                                datetime={datetime}
                                downloadUrl={downloadUrl}
                            />
                        ))
                    }
                </Stack>
            </Stack>
            <Footer />
        </VStack>
    )
}

export default Revista;