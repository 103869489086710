import { Image, ImageProps } from "@chakra-ui/react"

interface MagazineImageProps extends ImageProps {
    imageUrl: string;
    isMobile: boolean;
}

const MagazineImage = ({imageUrl, isMobile, ...props}: MagazineImageProps) => {
    return (
        <Image
            width={isMobile ? "200px":"400px"}
            src={imageUrl}
            position="relative"
            border="2px solid #ccc"
            transition="0.2s"
            boxShadow="0 10px 10px rgb(0 0 0 / 20%)"
            _hover={{
                transform: "translateY(-5px)",
            }}
            {...props}
        />
    )
}

export default MagazineImage;